// eslint-disable-next-line no-redeclare,no-unused-vars
function menuClick(evt, point, key) {
  if (!point) point = {};

  if (point.activate) {
    $(evt.currentTarget).toggleClass('active active-fix');
    toggleLocalFlag(key);
    if (point.jsFunc && window[point.jsFunc]) window[point.jsFunc](evt, point);
    return;
  } else if (point.noAjax && point.jsFunc) {
    if (isFunction(point.jsFunc)) point.jsFunc(evt, point);
    else if (window[point.jsFunc]) window[point.jsFunc](evt, point);
    return;
  } else if (point.link) {
    window.open(point.link);
    return;
  }

  var pm = {object: point.object, objectSub: point.objectSub};
  if (point.func) pm.send = {func: point.func};
  else if (point.jsFunc) pm.func = point.jsFunc;
  else {
    pm.func = 'overview';
    pm.type = getParamSettings(
      {object: point.object, objectSub: point.objectSub},
      'type'
    );
    if (!pm.type) pm.type = 'table';
  }

  if (point.id) pm.id = calculate(point.id);
  if (point.param) Object.assign(pm, point.param);
  if (point.send) Object.assign(pm.send, point.send);
  if (point.sendVar) pm.send[point.sendVar] = window[point.sendVar];
  if (point.clickSub) pm.clickSub = point.clickSub;

  var pmPrompt = [];
  for (var i = 1; i <= 10; i++) {
    var label = 'prompt';
    if (i > 1) label += i;
    if (point[label]) pmPrompt.push(point[label]);
  }
  if (!pmPrompt.length) ajax(pm);
  else {
    var popup = buildPromptPopup(
      pmPrompt,
      function (values) {
        pm.view = popup;
        if (!isArray(values)) values = [values];
        $.each(values, function (i, value) {
          var label = 'value';
          if (i > 0) label += i + 1;
          pm.send[label] = value;
        });
        pm.callback = function (xhr) {
          if (xhr.success) popup.close();
        };
        ajax(pm);
      },
      {noclose: 'all'}
    );
  }

  if (mobile == 'smartphone') $('nav').removeClass('hover');

  // fold all submenu sections on each item click (but only if sidebar-mini is active)
  if ($('#sidebar').hasClass('sidebar-mini')) {
    $('#sidebar')
      .find('div')
      .each(function () {
        $(this).removeClass('show');
      });
  }
}
